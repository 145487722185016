import * as React from 'react';
import { Link } from 'gatsby';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  motion,
  useScroll,
  AnimatePresence,
  useMotionValueEvent,
} from 'framer-motion';
import clsx from 'clsx';
import './NavBar.scss';

export interface ILinkItem {
  url: string;
  text: string;
  isCta?: boolean;
}

export const links: ILinkItem[] = [
  { url: '/home', text: 'Home' },
  { url: '/about', text: 'About' },
  { url: '/wedding-party', text: 'Wedding Party' },
  { url: '/lodging', text: 'Lodging' },
  { url: '/faqs', text: 'FAQs' },
  { url: '/registry', text: 'Registry' },
  { url: '/rsvp', text: 'RSVP', isCta: true },
];

const NavBar = () => {
  const { isOpen: isDrawerOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div className="hidden sm:flex flex-row w-full h-fit justify-center items-center gap-8 lg:gap-10 text-text-primary font-sans-light font-bold">
        {links.map(({ url, text, isCta = false }: ILinkItem, key) => {
          const linkClassName = clsx({
            'nav-bar-link text-base p-2': !isCta,
            'no-underline text-white text-base px-8 py-2 bg-gray-800 rounded-full':
              isCta,
          });
          return (
            <Link key={key} to={url} className={linkClassName}>
              {text}
            </Link>
          );
        })}
      </div>
      <MobileNavBar onOpen={onOpen} />
      <div className="sm:hidden flex flex-row h-fit justify-end items-end p-4 -mb-12 ">
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="Open Navbar"
          icon={<HamburgerIcon boxSize={8} />}
          onClick={onOpen}
          className=" text-text-primary"
        />
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={onClose} placement="right">
        <DrawerOverlay />
        <DrawerContent className="bg-background">
          <DrawerCloseButton size="lg" />
          <DrawerBody
            className="flex flex-col gap-2 py-16 font-sans text-text-primary"
            py={16}
          >
            {links.map(({ url, text }, key) => (
              <Link
                key={key}
                to={url}
                className="py-4 text-center"
                onClick={onClose}
              >
                {text}
              </Link>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

interface IMobileNavBarProps {
  onOpen: () => void;
}
const MobileNavBar = ({ onOpen }: IMobileNavBarProps) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, 'change', (latestScrollY) => {
    setIsVisible(latestScrollY > 20);
  });
  const className = clsx(
    'fixed top-0 w-full h-fit py-2 px-4 bg-background shadow justify-center items-center gap-0 flex flex-row z-50 rounded-b sm:hidden',
    !isVisible && 'hidden'
  );
  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          key="mobile-fixed-navbar"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.5 }}
          className={className}
        >
          <div className="left-navbar-area w-full" />
          <div className="center-navbar-area w-full">
            <p className=" text-center text-black text-sm font-normal leading-loose font-script">
              A & W
            </p>
          </div>
          <div className="right-navbar-area w-full">
            <IconButton
              variant="ghost"
              aria-label="Open Navbar"
              icon={<HamburgerIcon boxSize={8} />}
              onClick={onOpen}
              className=" text-text-primary float-right relative right-0"
            />
          </div>
        </motion.div>
      ) : undefined}
    </AnimatePresence>
  );
};

export default NavBar;
