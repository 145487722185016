import React from 'react';
import PageLayout from './src/components/PageLayout';
import { motion, AnimatePresence } from 'framer-motion';

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => {
  const pages = [
    '/home',
    '/about',
    '/wedding-party',
    '/lodging',
    '/registry',
    '/faqs',
    '/menu',
  ];
  return (
    <AnimatePresence>
      {pages.includes(props.location.pathname) ? (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{
            duration: 0.5,
          }}
        >
          <PageLayout {...props}>{element}</PageLayout>
        </motion.div>
      ) : (
        element
      )}
    </AnimatePresence>
  );
};

export default wrapPageElement;
