import * as React from 'react';

const Footer = () => {
  return (
    <footer className="relative w-full h-[256px] bg-footer bg-cover bg-no-repeat text-text-primary">
      <div className="h-full flex flex-col justify-center items-center gap-2 grow">
        <h2 className="w-fit font-script text-2xl leading-loose sm:text-3xl sm:leading-loose font-black py-4 px-1 border-b border-text-primary">
          A &amp; W
        </h2>
        <p className="font-sans text-base">09.22.2024</p>
      </div>
      <p className="p-2 text-xs absolute bottom-0 text-center w-full">
        {'Made with ❤️ by '}
        <a
          href="https://github.com/FanciestW"
          target="_blank"
          rel="noreferrer noopener"
          className="underline"
        >
          {'@FanciestW'}
        </a>
      </p>
    </footer>
  );
};

export default Footer;
