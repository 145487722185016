import React, { useEffect } from 'react';
import NavBar, { links } from './NavBar';
import Footer from './Footer';
import { Link, PageProps } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

const PageLayout = ({ children, location }: PageProps) => {
  useEffect(() => {
    localStorage.setItem('visited', 'true');
  });

  const isHomePage = location.pathname === '/home';
  const ctaLink = links.find((link) => link.isCta);
  const isRSVPEnabled = !!ctaLink;
  return (
    <motion.div className="layout-container min-h-screen flex flex-col justify-center bg-background text-text-primary">
      <div className="flex flex-col sm:flex-col-reverse gap-10 pb-12 sm:py-12">
        <NavBar />
        <div className="flex flex-col gap-4 items-center align-center">
          <h1 className="font-script text-5xl leading-loose sm:text-6xl sm:leading-loose text-center m-0 p-0">
            Alyson
            <span className="text-2xl max-sm:block max-sm:before:content-[' ']">
              {' + '}
            </span>
            William
          </h1>
          <h2 className="uppercase font-sans-light text-xl m-0 p-0">
            September 22, 2024 - Monroe, CT
          </h2>
          {isHomePage && isRSVPEnabled ? (
            <Link
              className="sm:hidden flex no-underline text-white text-base px-8 py-2 bg-gray-800 rounded-full"
              to={ctaLink.url}
            >
              {ctaLink.text}
            </Link>
          ) : null}
        </div>
      </div>
      <AnimatePresence mode="wait">
        <motion.main
          key={location.pathname}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.25 }}
          className="font-sans-light grow max-w-[1800px] w-full flex flex-col self-center justify-start px-8 sm:px-16 pb-16 sm:pb-12"
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </motion.div>
  );
};

export default PageLayout;
