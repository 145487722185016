import './src/styles/global.scss';
import CustomLayout from './wrapPageElement';

// This logic will suppress all console logs in production.
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.debug = () => {};
}

export const wrapPageElement = CustomLayout;
