module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"A&W Wedding","short_name":"A&W","start_url":"/","background_color":"#F5F5F5","theme_color":"#F5F5F5","display":"standalone","icon":"src/images/favicon.svg","crossOrigin":"use-credentials","icons":[{"src":"src/images/favicon-16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicon-32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon-48.png","sizes":"48x48","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"ecc58b125ae56324d3ae51f3fd31f87f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZVER3EMK13"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
